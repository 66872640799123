/*
	-- HEADER
*/

.recrutement-urgence {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #AA3333;
  z-index: 12;
  height: 40px;
  padding: 5px 0;
  color: #fff;
  text-align: center;
  line-height: 25px;
  font-size: 14px;
  ._media-s-up({
    font-size: 18px;
  });
  a {
    color: #fff;
  }
}

#main-header {
  position: fixed;
  top: 40px;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 11;
  background-color: white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

// Main Logo + Site name
#logo {
  display: flex;
  align-items: center;
  padding: 0 20px;

  span {
    color: #606c83;
  }

  // Screen > 989px;
  ._media-m-up({
    padding: 10px 0 10px 20px;
  });

  // Screen > 1199px
  ._media-l-up({
    padding: 20px 0 20px 20px;
  });
}

// Main-nav
nav {
  position: fixed;
  height: 100vh;
  z-index: 12;
  background-color: rgba(255, 255, 255, 0.95);
  top: 0;
  left: -120%;
  width: 100%;
  bottom: 0;
  padding-top: 30px;
  overflow-y: auto;
  ._transition(0.31s, left, cubic-bezier(.15, .6, .5, .9));

  &.shown {
    left: 0%;
  }

  & > ul {
    & > li {
      text-align: center;
      position: relative;

      &:hover,
      &.active {
        & > a {
          color: @primary;
        }

        &:after {
          background-color: @primary;
          width: 80%;
        }
      }

      a {
        display: block;
        color: #606c83;
        padding: 15px 0;
        font-size: 2rem;
        position: relative;
        ._transition;
      }

      &:after {
        content: ' ';
        display: block;
        margin: 0 auto;
        height: 1px;
        width: 0px;
        background-color: #cecece;
        ._transition(0.2s, width);
        width: 80%;
      }

      &.urgency:after {
        background-color: @red;
      }

      // Submenu "Le cabinet"
      &.has-submenu {
        ul {
          li {
            &:hover,
            &.active {
              & > a {
                color: @primary;
              }
            }

            a {
              font-size: 1.6rem;
              padding: 0 0 10px;
            }
          }
        }
      }
    }
  }

  // Screen > 359px
  ._media-xs-up({
    width: 75%;
    box-shadow: 0px 0px 10px 0px #949494;

    &:after {
      display: block;
      content: ' ';
      background-color: rgba(0, 0, 0, 0.5);
      height: 100vh;
      position: fixed;
      top: 0;
      right: -30%;
      width: 25.0%;
      ._transition(0.39s, right, cubic-bezier(.15, .6, .5, .9));
    }

    &.shown {
      &:after {
        right: 0%
      }
    }
  });


  // Screen > 989px;
  ._media-m-up({
    flex: 1;
    position: static;
    height: inherit;
    background-color: transparent;
    padding-top: 0;
    overflow-y: inherit;
    width: inherit;
    box-shadow: none;

    &:after {
      content: '';
      display: none;
    }

    & > ul {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;

      & > li {
        padding: 0 5px;

        &:after {

          margin-top: 5px;
          width: 0%;
        }

        a {
          padding: 0 10px;
          font-size: 1.6rem;
        }

        // Submenu "Le cabinet"
        &.has-submenu {
          position: relative;

          &:hover {
            ul {
              .slideInUp;
              display: block;
            }
          }

          ul {
            display: none;;
            position: absolute;
            left: 0px;
            top: 29px;
            background-color: white;
            width: 230px;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
            padding: 10px;

            a {
              padding: 10px 0;
              font-size: 1.6rem;
              ._transition;

              &:hover {
                color: @primary;
              }
            }
          }
        }
      }
    }
  });

  // Screen > 1199px
  ._media-l-up({
    & > ul {
      & > li {
        padding: 0 10px;

        // Submenu "Le cabinet"
        &.has-submenu {
          ul {
            top: 35px;
          }
        }
      }
    }
  });
}


/*
	-- FOOTER
*/
// Maps
#leaflet-maps {
  height: 350px;
  width: 100%;
}

// Coordinates
.coordinates {
  padding: 30px 20px 0;
  overflow: hidden;
}

// Icons landscape
.icons-landscape-footer {
  white-space: nowrap;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-top: 30px;

  /*
      --Sizes
  */

  [class*="squirrel"],
  [class*="rabbit"] {
    font-size: 1.6rem;
  }

  [class*="deer"] {
    font-size: 2.4rem;
  }

  [class~="fa-tree"] {
    font-size: 5rem;
  }

  [class*="trees"] {
    font-size: 7rem;

    &.item-last {
      font-size: 5.5rem;
    }
  }

  [class~="fa-tree-alt"] {
    font-size: 5rem;

    &.item-last {
      font-size: 4rem;
    }
  }

  /*
      --Positionning
  */

  [class*="tree"] {
    position: relative;
    top: 4px;
  }


  // Fast rabbit
  i:nth-child(1) {
    margin-right: 25px;

    // Static rabbit
    & + i {
      margin-right: 25px;

      // Double fir
      & + i {
        margin-right: 5px;

        // Single tree
        & + i {
          margin-right: 40px;

          // Squirrel
          & + i {
            margin-right: 15px;

            // Single fir
            & + i {
              margin-right: 5px;

              // Double fir
              & + i {
                margin-right: 25px;

                // Single tree
                & + i {
                  margin-right: 45px;
                }
              }
            }
          }
        }
      }
    }
  }
}


// Screen > 989px
._media-m-up ({
  .footer-top {
    display: flex;
    background-color: @gray;
  }

  #leaflet-maps {
    min-height: 500px;
  }

  .coordinates {
    flex: 0 0 40%;
    padding: 50px 20px 0;
    position: relative;

    p {
      position: relative;
      z-index: 2;
    }
  }

  .icons-landscape-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
});
