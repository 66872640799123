@charset "UTF-8";
@import 'normalize';


@import (reference) "../../node_modules/ChuckCSS/chuckcss/settings";
@import (reference) "../../node_modules/ChuckCSS/chuckcss/main/functions";
@import (reference) "../../node_modules/ChuckCSS/chuckcss/main/responsive";
@import "../../node_modules/ChuckCSS/chuckcss/main/animations";
@import "../../node_modules/ChuckCSS/chuckcss/main/base"; // <==== <html> & <body>
@import "../../node_modules/ChuckCSS/chuckcss/elements/titles";
@import "../../node_modules/ChuckCSS/chuckcss/elements/buttons";
@import "../../node_modules/ChuckCSS/chuckcss/elements/tables";
@import "../../node_modules/ChuckCSS/chuckcss/elements/lists";
@import "../../node_modules/ChuckCSS/chuckcss/elements/forms";
@import "../../node_modules/ChuckCSS/chuckcss/elements/images";
@import "../../node_modules/ChuckCSS/chuckcss/elements/loaders";
@import "../../node_modules/ChuckCSS/chuckcss/components/alerts";
@import "../../node_modules/ChuckCSS/chuckcss/main/helpers";
@import "../../node_modules/ChuckCSS/chuckcss/main/icons";
@import "../../node_modules/ChuckCSS/chuckcss/main/generator";


// FontAwesome
@import '../../node_modules/@fortawesome/fontawesome-pro/less/fontawesome';
@import '../../node_modules/@fortawesome/fontawesome-pro/less/light';
@import '../../node_modules/@fortawesome/fontawesome-pro/less/solid';
@import 'libraries/fonts';
@import 'settings-override';


// When menu is opened on smartphone
html.opened-menu {
    overflow: hidden !important;

    ._media-s-up({
        overflow:inherit !important;
    })
}

body {
    .sourcesans;
	background-color:white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    ._media-m-up({
        font-size:18px;
        font-size:1.8rem;
    });

    // When menu is opened on smartphone
    &.opened-menu {
        overflow-y: hidden;

        ._media-m-up({
            overflow:inherit !important;
        });
    }

    // container inside
    .cc-inside {
        ._inside(100%);
        padding:0 20px;

        // Screen > 989px
        ._media-m-up({
            ._inside(900px);
            padding:0;
        });

        // Screen > 1189px
        ._media-l-up({
            ._inside(1200px);
        });
    }

    // Main container with sticky header
    #main-content {
        padding-top: 103px;

        ._media-m-up({
            padding-top: 100px;
        });

        ._media-l-up({
            padding-top: 140px;
        });
    }
}

/* define text selection */
::-moz-selection {
    background: @primary;
    color: white;
}

::selection {
    background: @primary;
    color: white;
}


/* Import Helpers files */
@import 'helpers/hamburger';

/* Import libraries plugins files */
@import "libraries/leaflet";
@import "libraries/fancybox";
@import "libraries/flickity";
@import "libraries/wow";
@import "libraries/tippyjs";

/* Import Website styles */
@import 'website/common';
@import 'website/header-footer';
@import 'website/homepage';
@import 'website/cabinet';
@import 'website/contact';
@import 'website/error404';
@import 'website/advice-detail';

