// HOMEPAGE

// Slider
.homepage-slider {
	max-height:875px;
	position: relative;
	height: ~"calc(100vw * 0.4375)";
	background-color:@gray;
	overflow: hidden;

	// LOADER
	.wrap-loader {
		position: absolute;
		top:0;
		bottom:0;
		left:0;
		right:0;
		z-index:8;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color:@gray;

		.cc-loader {
			margin:0;
		}
	}

	// TITLES
	.wrap-titles {
		position: absolute;
		z-index:6;
		top:0;
		bottom:0;
		left:20px;
		right:20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		opacity: 0;
		._transition(0.4s, opacity);

		&.shown {
			opacity: 1;
		}

		h1,p {
			color:white;
			text-shadow:1px 2px 5px rgba(0,0,0,0.4);

			small {
				display: none;
				
			}
		}
	}

	// Carousel
	.main-carousel {
		height: 100%;
		opacity: 0;
		._transition(0.4s, opacity);

		&.shown {
			opacity: 1;
		}

		.carousel-cell {
			width: 100%;
			height: 100%;

			.mask {
				position: absolute;
				top:0;
				bottom:0;
				left:0;
				right:0;
				background-color:rgba(0,0,0,0.15);
			}
		}
	}

	._media-s-up({
		// TITLES
		.wrap-titles h1 small {
			display: block;
		}
	});
}


// Doctors
.wrap-doctors {

	// Each doctor item
	.doctor {
		img {
			display: block;
			border-radius: 100%;
			margin:0 auto 20px;
		}

		.description-full {
			color: lighten(@text-mc,22%);
		}
	}

	// Screen > 989px
	._media-m-up({
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		.doctor {
			flex:0 0 40%;
			padding-left:20px;
			padding-right:20px;

			img {
				max-width:250px;
			}
		}

		.about-us & {
			.doctor {
				flex: 0 0 100%;
				display: flex;
				background-color:@gray;
				padding:40px;
				margin-top:40px;

				picture {
					img {
						margin: 0;
					}
				}

				& > div  {
					padding-left: 60px;
					text-align: left;
					display: flex;
					flex-direction: column;
					justify-content: center;

					h3 {
						color: @primary;
						font-size: 3rem;
					}

					p {
						font-size: 1.5rem;
					}
				}
			}
		}
	});


	// Screen > 1199px
	._media-l-up({
		.doctor {
			img {
				max-width:300px;
			}
		}
	});
}