/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;

  &:focus {
    outline: none;
  }

  .flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100% !important;

    .flickity-slider {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  &.is-draggable {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    user-select: none;

    .flickity-viewport {
      cursor: move;
      cursor: -webkit-grab;
      cursor: grab;

      &.is-pointer-down {
        cursor: -webkit-grabbing;
        cursor: grabbing;
      }
    }
  }
}



/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  transform: translateY(-50%);


  &.previous { left: 10px; }
  &.next { right: 10px; }

  .flickity-button-icon {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
    fill: #333;
  }
}


/* ---- page dots ---- */
.flickity-page-dots {
  display: none;

  ._media-s-up({
    display: flex;
    position: absolute;
    z-index:7;
    width: 100%;
    bottom: 20px;
    padding: 0;
    margin: 0;
    list-style: none;
    justify-content: center;
    align-items: center;

    .dot {
      list-style: none;
      width: 18px;
      height: 18px;
      flex:0 0 18px;
      margin: 0 7px;
      background: white;
      border-radius: 50%;
      cursor: pointer;
      ._transition(0.3s, background-color);

      &.is-selected {
        background-color: @primary;
      }
    }
  });
}