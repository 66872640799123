// ADVICE DETAIL

.advice-detail {

	aside {
		position: relative;

		& > button {
			width: 100%;
			text-transform: uppercase;
			font-weight: 600;
			font-size: 1.6rem;
		}

		.wrap-advices-menu {
			display: flex;
			justify-content: center;
			position: fixed;
			top:0;
			left:0%;
			right:0;
			bottom:0;
			background-color:rgba(0,0,0,0.85);
			z-index: -2;
			padding:20px;
			opacity: 0;

			&.shown {
				z-index: 12;
				opacity: 1;

				& > ul {
					._translate(0px, 0px, 0px);
				}
			}

			// Close Advices manu on phone
			& > button {
				._reset;
				position: absolute;
				top: 10px;
				left: 50%;
				width: 40px;
				height: 40px;
				margin-left: -20px;
				background-color: white;
				color: #cc0000;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 2.5rem;
				border-radius: 100%;
			}

			& > ul {
				background-color: white;
				width:98%;
				margin:50px auto 0;
				border-radius: 5px;
				padding:20px;
				overflow-y: auto;
				._translate(-45px, 0px, 0px);
				._transition;

				& > li {
					padding:20px 0;

					&:nth-child(1) {
						padding-top: 0;
					}

					&:last-of-type {
						padding-bottom: 0;
					}

					&.active,
					&:hover {
						& > a {
							color: @primary;
						}
					}

					// Main parent link : advices titles
					& > a {
						display: flex;
						align-items: center;
						font-weight: 600;
						._transition;

						img {
							flex:0 0 60px;
							margin-right:15px;
						}
					}

					// Sub-menus
					ul {
						padding-top:10px;

						li {

							&.active,
							&:hover {
								a {
									color: @primary;
								}
							}

							a {
								display: block;
								padding: 5px 0;
								font-size: 1.4rem;
								._transition;
							}
						}
					}
				}
			}
		}
	}


	// Screen > 359px
	._media-xs-up({
		aside {
			.wrap-advices-menu {
				& > ul {
					& > li {
						ul {
							li {
								a {
									padding:5px 0 5px 75px;
								}
							}
						}
					}
				}
			}
		}
	});
	

	// Screen > 979px
	._media-m-up({
		.wrap-content {
			position: relative;
			padding:0;

			&:before {
				display: block;
				content: ' ';
				z-index: 1;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				width: 50%;
				background-color: @gray;
			}

			& > .cc-inside {
				position: relative;
				z-index: 2;
				display: flex;
			}

			aside {
				background-color: @gray;
				flex: 0 0 300px;
				max-width: 300px;
				padding-bottom: 70px;

				button	{
					display: none;
				}

				.wrap-advices-menu {
					display: block;
					position: static;
					background-color: transparent;
					z-index: inherit;
					padding: 0px;
					opacity: 1;

					& > ul {
						margin:0;
						background-color: transparent;
						width:auto;
						border-radius: 0px;
						padding:0px;
						overflow-y: inherit;
						._translate(0px, 0px, 0px);

						& > li {
							padding: 10px 20px 10px 10px;
							border-left: 3px solid transparent;
							._transition;

							&:hover,
							&.active {
								background-color: white;
								border-left-color:@primary;
							}

							&.active {
								ul {
									display: block;
								}
							}

							// Sub-menus
							ul {
								display: none;

								li a {
									padding:3px 0px 3px 85px;
									font-size: 1.6rem;
								}
							}
						}
					}
				}

			}

			section {
				background-color: white;
				flex:1;
				padding:70px 0 70px 40px;
				z-index: 12;
				opacity: 1;

				& > ul {
					._translate(0px, 0px, 0px);
				}
			}
		}
	});

	// Screen > 1199px
	._media-l-up({
		.wrap-content {
			aside {
				flex: 0 0 360px;
				max-width: 360px;
			}
		}
	});
}