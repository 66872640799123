// CONTACT
.contact {
	.legal {
		color:#777;
	}

	// Tippyjs
	.tippy-tooltip.error-theme {
		background: @red;
		color:white;
		font-size:1.4rem;

		// If native arrow
		.tippy-arrow {
    		border-top-color: @red;
    	}

    	// If custom svg arrow
    	.tippy-roundarrow {
			fill: @red;
		}
	}

	._media-s-up({
		.intro {
			margin-bottom: 80px
		}
	});

	._media-m-up({
		.intro {
			margin-bottom: 0;
			max-width:550px;
		}
	});

	._media-l-up({
		.intro {
			max-width:700px;
		}
	});

	// Captcha error message
	.captcha-error-wrapper {
		background-color: #f2e5e5;
		border:2px solid #e2c2c2;
		border-radius:3px;
		padding:10px;

		.captcha-error {
			font-size: 1.4rem;
			margin-bottom:10px;

			ul {
				color:#703535;
			}
		}
	}
	
}

.contact form {

	.form-item {
		margin: 20px 0;
	}

	label {
		padding-right:0;
		display: block;
		margin-bottom:5px;
		font-weight:600;
	}

	textarea,
	input {
		display: block;
		width: 100%;
		border:3px solid @primary;
		box-shadow: none;
		color: lighten(@text-mc, 20%);

		&[type="file"] {
			border-width: 0
		}
	}

	textarea {
		height:200px;
	}


	// Screen > 767px
	._media-s-up({
		display: flex;

		.left {
			flex:0 0 30%;
		}

		.right {
			flex:1;
			margin-left:30px;
		}
	});


	// Screen > 989px
	._media-m-up({
		.right {
			margin-left:100px;
		}

		.contain-captcha-submit {
			display: flex;
			align-items: center;
			justify-content: space-between;

			&:before,
			&:after {
				content:'';
				display: none;
			}

			& > div {
				flex: 0 0 40%;

				&.captcha {
					min-width:320px;
				}

				button {
					width: 100%;
				}
			}

		}

	});

}