// CABINET

.contain-img-text {


	.wrap-pictures {
		padding-bottom:40px;

		& > a {
			display: none;
			box-shadow: none;
			._transition;

			&:hover {
				box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.4);
			}

			img {
				display: block;
				margin: 0 auto; 
			}

			&:nth-child(1) {
				display: block;
				text-align: center;
			}
		}
	}

	// Screen > 989px
	._media-m-up({
		display:flex;

		.wrap-pictures {
			flex:0 0 50%;
			padding-right:40px;
			display: flex;
			flex-wrap: wrap;
			align-content: flex-start;
			justify-content: space-between;

			& > a {
				display: block;
				flex:0 0 180px;
				margin-bottom:15px;

				&:nth-child(1) {
					flex:0 0 100%
				}
			}
		}
	});
}