// COMMON CSS FILE
/*
    - Basics styles : strong, Hn
    - .body on CMS pages
    - 3 pushes : phone, address, opening hours
    - Sections, structure
    - Icons landscape
    - Advices pushes
    - breadcrumb
*/


/*
	- Basics Styles
*/
strong { 
	font-weight: 600
}
.sourcesans { 
	.sourcesans;
}
ol,
ul {
    ._reset;
    
    li {
        list-style:none;
    }
}
// Force text color when gray bg color
.cc-bg-gray {
    background-color:@gray;
}

// Title wrapper with gray text below
.wrap-title {
    text-align: center;

    h2, .h2-like,
    h1, .h1-like {
        font-weight: 700;
        font-size: 2.4rem;
        line-height:1.2;

        small {
            display: block;
            font-weight: 700;
            font-size:200%;
            letter-spacing: -3px;
            margin-bottom:-24px;
            line-height:1;
            color:@subtitle-mc;

            .cc-bg-gray & {
                color:white;
            }
        }
    }

    .intro {
        max-width:750px;
        margin:0 auto;
    }

    // Screen > 767px
    ._media-s-up({
        h2, .h2-like,
        h1, .h1-like {
            font-size:3rem;

            small {
                font-size:270%;
                margin-bottom:-35px;
            }
        }
    });

    // Screen > 989px
    ._media-m-up({
        h2, .h2-like,
        h1, .h1-like {
            font-size:3.6rem;

            small {
                font-size:333%;
                margin-bottom:-40px;
            }
        }
    });
}

button.cc-bg-primary,
a.btn.cc-bg-primary {
    position: relative;
    background-color:transparent;

    &:hover {
        background-color:transparent;

        &:after {
            width:100%;
        }
    }

    i {
        vertical-align: middle;
    }

    &:before {
        display: block;
        content: ' ';
        position:absolute;
        z-index:-2;
        top:0;
        left:0;
        bottom:0;
        width:100%;
        background-color: @primary;
    }

    &:after {
        display: block;
        content: ' ';
        position:absolute;
        z-index: -1;
        top:0;
        left:0;
        bottom:0;
        width:0%;
        background-color: darken(@primary,10%);
        ._transition(0.2s, width, cubic-bezier(.25,.1,.25,1));
    }

    ._media-m-up({
        font-size:1.8rem;
    });
}



/*
	- .body on CMs pages
*/
.body {	

    .advice-detail & {
        text-align: justify;

        p {
            padding-top: 20px;
        }

        ul {
            margin:20px 0;

            li {
                padding-bottom: 5px;
                list-style: disc inside;
            }
        }

        blockquote {
            ._reset;
            padding:0.3em 0.3em 0.3em 2em;
            border-left:4px solid @blockquotes-bc;
            font-style:italic;
            margin:1.5em 0;

            &.@{css-prefix} {
                /* Reverse blockquote : right alignment */
                &-reverse {
                    text-align:right;
                    padding:0.3em 2em 0.3em 0.3em;
                    border-left:0;
                    border-right:4px solid @blockquotes-bc;

                    
                }
            }


            /* Footer into Blockquote */
            footer {
                font-style:normal;
                font-size:80%;
                padding-top:1em;
            }
        }
    }
    h2 {
        font-size: 2.4rem;
        font-weight: 600;
        line-height: 1.3;
    }

    a {
        color:@primary;
    }

    .legend {
        text-align: center;
        color:#888888;
        display: block;
        padding-top:5px;
        font-style: italic;
    }

    img {
        &.cc-centered,
        &.img-right,
        &.img-left {
            display: block;
            margin:0 auto 30px;
        }
    }

    // Video : Les maladies parodontales
    video {
        display: block;
        width:100%;
        max-width:500px;
        height:auto;
        margin:0 auto 40px;

        &.large {
            max-width: 800px;

            &::-webkit-media-controls-fullscreen-button {  display: block; }
        }
    }
    video::-webkit-media-controls-volume-slider {  display: none; }
    video::-webkit-media-controls-mute-button {  display: none; }
    video::-webkit-media-controls-fullscreen-button {  display: none; }

    ._media-l-up({
        img {
            &.img-left {
                float:left;
                margin: 0 30px 30px 0;
            }
            &.img-right {
                float:right;
                margin: 0 0px 30px 30px;
            }
        }
    });
}


/*
    - 3 pushes : phone, address, opening hours
*/
.wrap-pushes {
    display: flex;

    .push {
        flex:1;
        color: white;
        padding:10px;
        overflow:hidden;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        p, div {
            display: none;
        }

        a {
            color:white;
        }

        &.phone,
        &.opening {
            background-color: @primary;
        }

        &.address {
            background-color: #62aeae;
        }
    }

    // Screen > 767px
    ._media-s-up ({

        .push {
            padding:20px;
            display:block;

            svg,i {
                position: absolute;
                left:-20px;
                font-size:20rem;
                top:50%;
                margin-top:-100px;
                z-index: 3;    
            }

            p, div {
                display: block;
                position: relative;
                z-index: 4;
                color: white;

                &.push-title {
                    text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
                }

                ul {
                    li {
                        text-align: left;
                        display: flex;
                        align-items: baseline;

                        .day {
                            white-space: nowrap
                        }

                        .sep {
                            border-bottom:2px dotted white;
                            flex:1;
                            margin-right:10px;
                        }

                        .hours {
                            white-space: nowrap
                        }
                    }
                }
            }

            &.phone,
            &.opening {
                svg, i {
                    color: #62aeae;
                }
            }

            &.address {
                svg, i {
                    color: #5aa3a3;
                }

                div {
                    line-height: 1.2;
                }
            }

            &.opening {
                svg, i {
                    left:-40px;
                }
            }
        }
    });

    // Screen > 989px
    ._media-m-up ({
        .push {
            svg,i {
                font-size:30rem;
                margin-top:-150px;
            }
        }
    });
}
// Pushes display info on phone, when icon is clicked
.pushes-phone {
    background-color:#62aeae;
    color:white;
    
    p, ul, li, a {
        color:white;
    }

    .push {
        padding:20px;
        display: none;
    }
}



/*
    - Sections, structure
*/
.section {
    padding:30px 0;

    ._media-s-up({
        padding:45px 0;
    });

    ._media-m-up({
        padding:40px 0;

        .homepage & {
            padding:70px 0;
        }
    });

    ._media-l-up({
        padding:80px 0;

        &.border-bottom {
            padding:40px 0;
        }

        .homepage & {
            padding:100px 0;
        }
    });

    &.contain-icons-landscape {
        padding-bottom: 0;
    }
}
.border-bottom {
    border-bottom:4px solid #e6e6e6
}



/*
    - Icons landscape
*/
.contain-icons-landscape {
    position: relative;

    /*
        --Colors
    */
    [class*="tree"] { color: @green; }
    [class~="fa-tree-alt"] { color: darken(@green,10%); }
    [class*="deer"],
    [class*="squirrel"] { color: #a89479; }
    [class~="fa-skiing"],
    [class*="rabbit"] { color: #a8a8a8; }
    [class*="mountains"] { color: #f0f0f0; }
    [class~="fa-mountain"] {
        color: #f6f6f6;

        &.item-first {
            color: #f0f0f0;
        }
    }
    [class~="fa-clouds"] { color: #cdd3e3; }


    & > .cc-inside {
        position:relative;
        z-index: 3;
    }
}

.icons-landscape {
    /*
        --Sizes
    */
    [class*="squirrel"],
    [class*="rabbit"] { font-size: 1.6rem; }
    [class*="deer"] { font-size: 2.4rem; }
    
    .left-scene {
        [class~="fa-mountain"] { font-size: 14rem; }
        [class*="mountains"] { font-size: 11.5rem; }
        [class*="trees"] { font-size: 5rem; }
        [class~="fa-tree-alt"] { 
            font-size: 2.5rem;

            &.item-first {
                font-size: 4rem;
            }
        }
        [class~="fa-clouds"] { font-size: 5rem; }
    }

    .right-scene {
        [class~="fa-mountain"] { 
            font-size: 13rem; 

            &.item-first {
                font-size: 9.5rem;
            }
        }
        [class*="trees"] { 
            font-size: 4.5rem;

            &.item-first {
                font-size: 5rem;

            }
        }
        [class~="fa-tree"] { 
            font-size: 4rem;
        }
    }

    /*
        --Positionning
    */
    [class~="fa-squirrel"],
    [class~="fa-rabbit"] { position: relative; top:1px; }
    .left-scene {
        position: relative;
        overflow: hidden;
        min-width:250px;
        max-width:250px;
        margin:0 auto;
        
        .behind {
            position: relative;

            [class~="fa-mountain"] {
                position: relative;
                top:15px;
            }

            [class*="mountains"] {
                position: absolute;
                left:95px;
                bottom: -20px;
                z-index: 1;
            }

            [class~="fa-clouds"] {
                position: absolute;
                top:20px;
                right:35px;
                z-index: 2;
            }
        }

        .foreground {
            position: absolute;
            z-index:2;
            left:25px;
            bottom:-3px;

            // Second tree
            i + i {
                margin-left:5px;

                // Third tree
                & + i {
                    // First rabbit
                    & + i {
                        margin-left:10px;

                        // Second Rabbit
                        & + i {
                            margin-left:15px;
                        }
                    }
                }
            }
        }
    }

    .right-scene {
        display: none;
    }


    // Screen > 767px
    ._media-s-up({
        display: flex;
        justify-content: space-between;
        align-items:flex-end;
        margin-top:-80px;

        /*
            --Sizes
        */
        .left-scene {
            [class~="fa-mountain"] { font-size: 16rem; }
            [class*="mountains"] { font-size: 13.5rem; }
            [class*="trees"] { font-size: 6rem; }
            [class~="fa-tree-alt"] { 
                font-size: 3.5rem;

                &.item-first {
                    font-size: 4.5rem;
                }
            }
        }

        .right-scene {
            [class~="fa-mountain"] { 
                font-size: 16rem; 

                &.item-first {
                    font-size: 9.5rem;
                }
            }
            [class*="trees"] { 
                font-size: 5rem;

                &.item-first {
                    font-size: 6rem;

                }
            }
            [class~="fa-tree"] { 
                font-size: 4rem;
            }

            [class~="fa-skiing"] {
                font-size: 1.5rem;
            }
        }

        /*
            --Positionning
        */
        .left-scene {
            margin: inherit;
            min-width:300px;
            max-width:300px;
            flex:0 0 300px;

            .behind {
                [class~="fa-mountain"] {
                    top:10px;
                }

                [class*="mountains"] {
                    left:125px;
                    bottom: -27px;
                }
            }

            .foreground {
                left:35px;
                bottom:-5px;

                // Second tree
                i + i {
                    margin-left:10px;

                    // Third tree
                    & + i {
                        // First rabbit
                        & + i {
                            margin-left:30px;

                            // Second Rabbit
                            & + i {
                                margin-left:20px;
                            }
                        }
                    }
                }
            }
        }

        .right-scene {
            position: relative;
            overflow: hidden;
            min-width:300px;
            max-width:300px;
            flex:0 0 300px;
            display: block;

            .behind {
                text-align: right;
                position: relative;

                .item-second {
                    position: relative;
                    top:10px;
                }

                .item-first {
                    position: absolute;
                    bottom: -10px;
                    z-index: 1;
                    right:150px;
                }

                [class~="fa-skiing"] {
                    position: absolute;
                    z-index:-1;
                    top:10px;
                    right:108px;            
                }
            }

            .foreground {
                position: absolute;
                z-index:2;
                left:0;
                bottom:-5px;

                // First tree after Squirrel
                i + i {
                    margin-left:45px;

                    // Second tree
                    & + i {
                        margin-left: 10px;
                        // Third tree
                        & + i {
                            margin-left:5px;

                            // Deer
                            & + i {
                                margin-left:10px;
                            }
                        }
                    }
                }
            }
        }
    });

    // Screen > 989px
    ._media-m-up({

        /*
            --Sizes
        */
        .left-scene {
            [class~="fa-mountain"] { font-size: 20rem; }
            [class*="mountains"] { font-size: 15.5rem; }
            [class*="trees"] { font-size: 7rem; }
            [class~="fa-tree-alt"] { 
                font-size: 4.5rem;

                &.item-first {
                    font-size: 5.5rem;
                }
            }
        }

        .right-scene {
            [class~="fa-mountain"] { 
                font-size: 20rem; 

                &.item-first {
                    font-size: 14.5rem;
                }
            }
            [class*="trees"] { 
                font-size: 6.5rem;

                &.item-first {
                    font-size: 7.5rem;

                }
            }
            [class~="fa-tree"] { 
                font-size: 5rem;
            }
        }

        /*
            --Positionning
        */
        .left-scene {
            min-width:365px;
            max-width:365px;
            flex:0 0 365px;

            .behind {
                [class~="fa-mountain"] {
                    top:20px;
                }

                [class*="mountains"] {
                    left:165px;
                    bottom: -30px;
                }

                [class~="fa-clouds"] {
                    top:40px;
                }
            }

            .foreground {
                left:55px;
                bottom:-6px;

                // Second tree
                i + i {
                    margin-left:15px;

                    // Third tree
                    & + i {
                        margin-left:10px;

                        // First rabbit
                        & + i {
                            margin-left:40px;
                        }
                    }
                }
            }
        }

        .right-scene {
            min-width:365px;
            max-width:365px;
            flex:0 0 365px;
            

            .behind {
                .item-second {
                    top:20px;
                }

                .item-first {
                    bottom: -20px;
                    right:160px;
                }

                [class~="fa-skiing"] {
                    top:20px;
                    right:135px;            
                }
            }

            .foreground {
                bottom:-6px;

                // First tree after Squirrel
                i + i {

                    // Second tree
                    & + i {
                        
                        // Third tree
                        & + i {
                            
                            // Deer
                            & + i {
                                margin-left:20px;
                            }
                        }
                    }
                }
            }
        }
        
    });

    // Screen > 1199px
    ._media-l-up({
        margin-top:-130px;
        /*
            --Sizes
        */
        .left-scene {
            [class~="fa-mountain"] { font-size: 25rem; }
            [class~="fa-tree-alt"] { 
                font-size: 3.6rem;

                &.item-first {
                    font-size: 4.8rem;
                }
            }
        }

        .right-scene {
            [class~="fa-mountain"] { 
                font-size: 25rem; 

                &.item-first {
                    font-size: 15rem;
                }
            }
            [class*="trees"] { 
                font-size: 5.5rem;

                &.item-first {
                    font-size: 7rem;
                }
            }
        }

        /*
            --Positionning
        */
        .left-scene {
            min-width:410px;
            max-width:410px;
            flex:0 0 410px;

            .behind {
                [class*="mountains"] {
                    left:215px;
                }

                [class~="fa-clouds"] {
                    top:90px;
                }
            }

            .foreground {
                left:35px;
            }
        }

        .right-scene {
            min-width:420px;
            max-width:420px;
            flex:0 0 420px;

            .behind {
                .item-first {
                    bottom: -15px;
                    right:230px;
                }

                [class~="fa-skiing"] {
                    right:169px;            
                }
            }

            .foreground {
                bottom:-6px;
                left: 50px;
            }
        } 
    });
}



/*
    -- Advices pushes
*/
.wrap-advices {

    .advice {
        a {
            padding:20px;
            ._transition;

            &:hover {
                background-color: @gray;

                h3 {
                    color:darken(@primary, 10%);
                }

                .img-wrapper {
                    &:before {
                        ._scale(1.3);
                    }
                }
            }

            h3 {
                ._transition;
            }

            .img-wrapper {
                margin:0 auto;
                position: relative;

                img {
                    position: relative;
                    z-index:1;
                }

                &:before {
                    display: block;
                    content: ' ';
                    background-color:darken(@primary, 10%);
                    height:100px;
                    width:100px;
                    border-radius:100%;
                    position:absolute;
                    top:10px;
                    left:50%;
                    margin-left:-50px;
                    ._transition;
                }
            }
        }
    }

    // Screen > 767px
    ._media-s-up({
        display:flex;
        flex-wrap: wrap;

        .advice {
            flex:0 0 50.0%;
        }
    });

    // Screen > 767px
    ._media-m-up({
        .advice {
            flex:0 0 25.0%;

            body:not(.advices) & {
                padding-top:30px;
            }
        }
    });
}



/*
    - Breadcrumb
*/
.breadcrumb {
    padding:15px 20px;

    ol {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        i {
            margin:0 20px;
        }
    }
}


// VIDEOS
.video-wrapper {
    max-width: 800px;
    margin: 30px auto 0;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;

    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}