@charset "UTF-8";

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}
.animated.infinite {
  animation-iteration-count: infinite;
}

@media (prefers-reduced-motion) {
  .animated {
    animation: unset !important;
    transition: none !important;
  }
}


/*
  ** ANIMATIONS **
*/

/*
  Used in "Landscape Icons"
*/
.cloudMove {
  animation-name: cloudMove;
  animation-duration: 100s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(.25,.1,.25,1);
}
@keyframes cloudMove {
  0% {
    transform: translate3d(0px, 0px, 0);
  }
  50% {
    transform: translate3d(-240%, 0, 0);
  }
  100% {
    transform: translate3d(0px, 0px, 0);
  }
}
.skiMove {
  animation-name: skiMove;
  animation-duration: 8s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(.42,0,1,1);
}
@keyframes skiMove {
  0% {
    ._translate(0px, 0px, 0);
    ._scale(-1, 1);
    ._rotate(30deg);
  }
  100% {
    ._translate(-113px, 176px, 0);
    ._scale(-1, 1);
    ._rotate(30deg);
  }
}
.windInTreeRight {
  animation-name: windInTreeRight;
  animation-duration: 20s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(.25,.1,.25,1);
  transform-origin: bottom right;

  &.delay-1 {
    animation-delay: 0.15s;
  }
  &.delay-2 {
    animation-delay: 0.3s;
  }
}
@keyframes windInTreeRight {
  0%,7%,22%,27%,67%,76% {
    transform: skew(0deg, 0deg);
  }
  12% {
    transform: skew(4deg, 0deg);
  }
  72% {
    transform: skew(5deg, 0deg);
  }
  75% {
    transform: skew(-1deg, 0deg);
  }
  100% {
    transform: skew(0deg, 0deg);
  }
}
.windInTreeLeft {
  animation-name: windInTreeLeft;
  animation-duration: 20s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(.25,.1,.25,1);
  transform-origin: bottom right;

  &.delay-1 {
    animation-delay: 0.15s;
  }
  &.delay-2 {
    animation-delay: 0.3s;
  }
}
@keyframes windInTreeLeft {
  0%,7%,22%,27%,67%,76% {
    transform: skew(0deg, 0deg);
  }
  12% {
    transform: skew(-4deg, 0deg);
  }
  72% {
    transform: skew(-5deg, 0deg);
  }
  75% {
    transform: skew(1deg, 0deg);
  }
  100% {
    transform: skew(0deg, 0deg);
  }
}

/*
  -- SlideInUp --
  Used in :
    - Homepage : 3 doctors
    - Homepage : Advices pushes;
*/
@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    opacity:0;
  }
  to {
    opacity:1;
    transform: translate3d(0, 0, 0);
  }
}
.slideInUp {
  animation-name: slideInUp;
}

/*
  -- SlideInUpMenu --
  Used in :
    - Header : show submenu "Le Cabinet";
*/
@keyframes SlideInUpMenu {
  from {
    transform: translate3d(0, 30px, 0);
    opacity:0;
  }
  to {
    opacity:1;
    transform: translate3d(0, 0, 0);
  }
}
.slideInUp() {
  animation-name: SlideInUpMenu;
  animation-duration: 0.3s;
  animation-fill-mode: both;
}