// ERROR 404

.error404 {
	#main-content {
		padding-top:0;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;		
		min-height: 100vh;
		height: 100vh;
		/* bgcolor */
		background: #b4cad5;
		background: linear-gradient(to bottom, #b4cad5 0%,@gray 60%,@gray 60%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b4cad5', endColorstr='#f6f6f6',GradientType=0 );
	}

	.wrap-title {
		position: relative;
		z-index: 2;

		.h1-like {
			font-size: 10rem;

			small {
				font-size: 9rem;
				margin-bottom: -55px;
			}

			p {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			i {
				font-size: 6.5rem;
				position: relative;
				top: 2px;
			}
		}
	}

	// Icons
	.contain-icons-landscape {
		position: absolute;
		z-index: 1;
		bottom: 0;
		left: 0;
		right: 0;


		/*
	        --Colors
	    */
	    
	    [class*="mountains"] { color: #dfdfdf; }
	    [class~="fa-mountain"] {
	        color: #d3d3d3;

	        &.item-first {
	            color: #dfdfdf;
	        }
	    }

		.icons-landscape {
			margin-top:0;
		}
	}

	// Screen > 767px
	._media-s-up({
		.wrap-title {
			.h1-like {
				font-size: 15rem;

				small {
					font-size: 12rem;
					margin-bottom: -95px;
				}

				i {
					font-size: 9.5rem;
					top: 6px;
				}
			}
		}
	});
}